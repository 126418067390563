import styled from 'styled-components/macro'

export const Title = styled.h3`
  font-size: calc(28 / 16 * 1rem);
  font-weight: 400;
  margin-bottom: 19px;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledLabel = styled.label``

export const Line = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 36px;
  margin-bottom: 19px;
  align-items: center;
`

export const StyledSelect = styled.select`
  width: 331px;
  min-height: 36px;
  background-color: var(--light-grey);
  border-radius: 5px;
`
export const StyledInput = styled.input`
  background-color: var(--light-grey);
  border-radius: 5px;
  min-height: 36px;
  border: 0;
  width: ${p => p.width}px;
  margin-left: 8px;
`
export const StyledTextArea = styled.textarea`
  background-color: var(--light-grey);
  border-radius: 5px;
  max-height: 75px;
  border: 0;
  width: ${p => p.width}px;
  resize: none;
`
export const Button = styled.button`
  height: 36px;
  width: 82px;
  font-size: calc(12 / 16 * 1rem);
  background-color: var(--darker-grey);
  border-radius: 5px;
  border: 0;
  color: var(--white);
  font-family: 'Rubik';
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  :hover {
    background-color: var(--light-green);
  }
`
