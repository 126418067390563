import {createGlobalStyle} from 'styled-components/macro'

const GlobalStyles = createGlobalStyle`
  body {
    /* Colors: */
    --black: #000;
    --white: #fff;
    --dark-grey: #343434;
    --darker-grey: #1A1A1A;
    --light-grey: #f4f4f4;
    --light-green: #83A37A;
    --light-blue: #A4DEF9;
    --dark-blue: #4F7CAC;
  }
`

export default GlobalStyles
