const BREAKPOINTS = {
  phoneMax: 600,
  tabletMax: 1050,
  laptopMax: 1300,
}
export const QUERIES = {
  phoneAndUp: `(min-width: ${BREAKPOINTS.phoneMax / 16}rem)`,
  tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
}
