import * as React from 'react'
import styled from 'styled-components/macro'
import RequestQuotePopup from './RequestQuotePopup'
import Button from 'common/Button'
import {QUERIES} from 'common/constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 16px;
  @media ${QUERIES.tabletAndUp} {
    padding: 128px;
  }
  gap: 16px;
  /* Background Gradient */

  background: linear-gradient(180deg, #343434 8.87%, #1a1a1a 100%);
`

const Title = styled.h2`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  @media ${QUERIES.tabletAndUp} {
    font-size: 50px;
    line-height: 59px;
  }
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
`

const Message = styled.p`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: calc(16 / 16 * 1rem);
  line-height: 20px;
  @media ${QUERIES.tabletAndUp} {
    font-size: 24px;
    line-height: 30px;
  }
  text-align: center;
  margin-bottom: 16px;
`

const ComingSoon = () => {
  const [showQuoteModal, setShowQuoteModal] = React.useState(false)
  return (
    <Container>
      <Title>Coming Soon</Title>
      <Message>
        Follow us on social media to stay up to date on the launch of this page!
        In the meantime, receive a custom quote from our team by clicking on the
        link below. <br />
        <br />
        We’re excited for what the future holds!
      </Message>
      <Button onClick={() => setShowQuoteModal(true)}>Request a quote</Button>
      <RequestQuotePopup
        setShowQuoteModal={setShowQuoteModal}
        showQuoteModal={showQuoteModal}
      />
    </Container>
  )
}
export default ComingSoon
