import * as React from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import styled from 'styled-components/macro'
import ServicesMobileMenu from 'common/ServicesMobileMenu'
import {List, CaretRight, X} from 'phosphor-react'
import Logo from 'assets/images/Logo1.svg'
import {QUERIES} from 'common/constants'
import Facebook from 'assets/images/SocialIcons/Facebook.png'
import Instagram from 'assets/images/SocialIcons/Instagram_White.png'
import LinkedIn from 'assets/images/SocialIcons/LinkedIn_White.png'
import TikTok from 'assets/images/SocialIcons/TikTok_White.png'
import Twitter from 'assets/images/SocialIcons/Twitter_White.png'
import YouTube from 'assets/images/SocialIcons/YouTube_White.png'

const Container = styled.div`
  /* position: absolute; */
  width: 100%;
  background-color: ${p =>
    p.visible === 'true' ? 'var(--darker-grey)' : 'inherit'};
  transition: height 0.1s ease;
  height: ${p => (p.visible === 'true' ? '100vh' : '74px')};
  @media ${QUERIES.tabletAndUp} {
    height: 0;
  }
`

const DropdownList = styled.div`
  display: visible;
  max-height: ${p => (p.visible === 'true' ? '1000px' : 0)};
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`

const StyledDropdownLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
  font-family: 'Rubik';
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  padding: ${p => (p.visible === 'true' ? '24px 16px' : 0)};
  width: 100%;
  background-color: var(--darker-grey);
  cursor: pointer;
  height: 72px;
  max-height: ${p => (p.visible === 'true' ? '72px' : 0)};
  overflow: hidden;
`

const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--white);
  img {
    height: 42px;
  }
  padding: 16px;
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
  transition: all 0.5s ease;
`

const MenuList = styled(List)`
  display: ${p => (p.visible === 'true' ? 'flex' : 'none')};
  :hover {
    cursor: pointer;
  }
`

const MenuClose = styled(X)`
  display: ${p => (p.visible === 'true' ? 'flex' : 'none')};
  :hover {
    cursor: pointer;
  }
`

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  h3 {
    font-size: calc(18 / 16 * 1rem);
    font-style: italic;
    font-family: 'Mulish';
    font-weight: 500;
    margin-bottom: 8px;
  }
  display: ${p => (p.visible === 'true' ? 'block' : 'none')};
`

const SocialSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
`

const SocialIcons = styled.div`
  display: flex;
  gap: 8px;
  img {
    height: 24px;
    width: 24px;
  }
`

const MobileMenu = () => {
  const [showDropdown, setShowDropdown] = React.useState(false)
  const [showMobileServices, setShowMobileServices] = React.useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    setShowDropdown(false)
  }, [location])

  return (
    <Container visible={String(showDropdown)}>
      <DropdownHeader visible={showDropdown}>
        <img src={Logo} alt="Golf Invite Logo" onClick={() => navigate('/')} />
        <MenuList
          size={32}
          visible={String(!showDropdown)}
          color="white"
          onClick={() => setShowDropdown(true)}
        />
        <MenuClose
          size={32}
          visible={String(showDropdown)}
          color="white"
          onClick={() => setShowDropdown(false)}
        />
      </DropdownHeader>
      <DropdownList visible={String(showDropdown)}>
        <StyledDropdownLink to="/" visible={String(!showMobileServices)}>
          <span>Home</span>
          <CaretRight />
        </StyledDropdownLink>
        <StyledDropdownLink
          as="div"
          onClick={() => setShowMobileServices(true)}
          visible={String(!showMobileServices)}
        >
          <span>Services</span>
          <CaretRight />
        </StyledDropdownLink>
        <ServicesMobileMenu
          showDropdown={showDropdown}
          showMobileServices={showMobileServices}
          setShowMobileServices={setShowMobileServices}
        />
        <StyledDropdownLink to="/shop" visible={String(!showMobileServices)}>
          <span>Shop</span>
          <CaretRight />
        </StyledDropdownLink>
        <StyledDropdownLink to="/about" visible={String(!showMobileServices)}>
          <span>About</span>
          <CaretRight />
        </StyledDropdownLink>
        <StyledDropdownLink to="/contact" visible={String(!showMobileServices)}>
          <span>Contact</span>
          <CaretRight />
        </StyledDropdownLink>
      </DropdownList>
      <Footer visible={String(showDropdown)}>
        <SocialSection>
          <h3>Follow Us</h3>
          <SocialIcons>
            <a href="linkedin.com">
              <img src={LinkedIn} alt="LinkedIn" />
            </a>
            <a href="instagram.com">
              <img src={Instagram} alt="Instagram" />
            </a>
            <a href="facebook.com">
              <img src={Facebook} alt="Facebook" />
            </a>
            <a href="tiktok.com">
              <img src={TikTok} alt="TikTok" />
            </a>
            <a href="youtube.com">
              <img src={YouTube} alt="YouTube" />
            </a>
            <a href="twitter.com">
              <img src={Twitter} alt="Twitter" />
            </a>
          </SocialIcons>
        </SocialSection>
      </Footer>
    </Container>
  )
}
export default MobileMenu
