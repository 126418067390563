import * as React from 'react'

import {BrowserRouter as Router} from 'react-router-dom'
import Routes from './Routes'
import GlobalStyles from 'assets/GlobalStyles'
import './App.css'
import ReactGA from 'react-ga4'

function App() {
  ReactGA.initialize('G-7BQ67WTCXL')
  return (
    <Router>
      <GlobalStyles />
      <Routes />
    </Router>
  )
}

export default App
