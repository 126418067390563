//
import React from 'react'
import Spinner from 'react-spinkit'
import styled from 'styled-components/macro'

const Overlay = styled.div`
   {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${p => (p.overlay ? 'rgba(0, 0, 0, 0.3)' : 'none')};
    z-index: 9999;
    display: grid;
    justify-content: center;
    align-items: center;
  }
`

const LoadingSpinner = ({
  spinnerType = 'ball-scale-multiple',
  overlay = false,
}) => (
  <Overlay overlay={overlay}>
    <Spinner name={spinnerType} color="green" fadeIn="half" />
  </Overlay>
)

export default LoadingSpinner
