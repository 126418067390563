export const submitQuoteRequest = ({
  setSubmitSuccess,
  setSubmitting,
  formID,
}) => {
  setSubmitting(true)
  let myForm = document.getElementById(formID)
  let formData = new FormData(myForm)
  // const email = document.getElementById('email').value
  const email = myForm.elements.namedItem('email').value
  if (email) {
    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setSubmitting(false)
        setSubmitSuccess(true)
        console.info('Form successfully submitted')
      })
      .catch(error => {
        setSubmitting(false)
        alert(error)
      })
  } else {
    alert('Please enter an email address')
    setSubmitting(false)
  }
}
