import * as React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components/macro'
import {Plus, CaretLeft, Minus} from 'phosphor-react'

const DropdownList = styled.div`
  display: visible;
  max-height: ${p => (p.visible ? '1000px' : 0)};
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  background-color: var(--darker-grey);
`

const ServicesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
  font-family: 'Rubik';
  text-transform: uppercase;
  font-style: normal;
  text-decoration: none;
  padding: 24px 16px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--darker-grey);
  cursor: pointer;
  height: 72px;
  max-height: 72px;
  overflow: hidden;
`

const StyledDropdownLink = styled(Link)`
  font-family: 'Mulish';
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  font-size: calc(16 / 16 * 1rem);
  font-weight: 300;
  font-style: normal;
  text-decoration: none;
  padding: 2px 16px;
  width: 100%;
  cursor: pointer;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 8px 16px;
  padding-left: 28px;
  font-size: calc(20 / 16 * 1rem);
  font-style: italic;
  cursor: pointer;
  font-weight: ${p => (p.active ? 700 : 'normal')};
  background-color: ${p => (p.active ? 'var(--darker-grey)' : 'inherit')};
  transition: all 0.2s ease;
`

const SubMenuItem = styled.div`
  max-height: ${p => (p.visible ? '300px' : 0)};
  flex-direction: column;
  gap: 8px;
  transition: all 0.5s ease;
  overflow: hidden;
  background-color: var(--darker-grey);
  padding: ${p => (p.visible ? '16px 0 24px 0' : '0')};
  padding-left: 16px;
`

const AdditionalHeader = styled(StyledDropdownLink)`
  font-style: italic;
  font-weight: 300;
`

const AdditionalServicesLink = styled(StyledDropdownLink)`
  margin-left: 16px;
`

const ServicesMobileMenu = ({
  showDropdown,
  showMobileServices,
  setShowMobileServices,
}) => {
  const [showServicesSubMenuItem, setShowServicesSubMenuItem] =
    React.useState('')

  const headerClick = item => {
    if (showServicesSubMenuItem === item) {
      setShowServicesSubMenuItem('')
    } else {
      setShowServicesSubMenuItem(item)
    }
  }

  return (
    <DropdownList visible={showDropdown && showMobileServices}>
      <ServicesHeader as="div" onClick={() => setShowMobileServices(false)}>
        <CaretLeft /> Services
      </ServicesHeader>
      <Header
        onClick={() => headerClick('planning')}
        active={showServicesSubMenuItem === 'planning'}
      >
        <span>Planning</span>
        {showServicesSubMenuItem === 'planning' ? <Minus /> : <Plus />}
      </Header>
      <SubMenuItem visible={showServicesSubMenuItem === 'planning'}>
        <StyledDropdownLink to="/event-planning">
          Event Planning
        </StyledDropdownLink>
        <StyledDropdownLink to="/sweetspot">
          Sweet Spot Software
        </StyledDropdownLink>
        {/* <StyledDropdownLink to="/event-liability-coverage">
          Event Liability Coverage
        </StyledDropdownLink> */}
      </SubMenuItem>
      <Header
        onClick={() => headerClick('gifts')}
        active={showServicesSubMenuItem === 'gifts'}
      >
        <span>Gifts</span>
        {showServicesSubMenuItem === 'gifts' ? <Minus /> : <Plus />}
      </Header>
      <SubMenuItem visible={showServicesSubMenuItem === 'gifts'}>
        <StyledDropdownLink to="/gifts/apparel">Apparel</StyledDropdownLink>
        <StyledDropdownLink to="/gifts/golfer-gifts">
          Golfer Gifts
        </StyledDropdownLink>
        <StyledDropdownLink to="/gifts/promotional">
          Promotional Products
        </StyledDropdownLink>
      </SubMenuItem>
      <Header
        onClick={() => headerClick('on-course')}
        active={showServicesSubMenuItem === 'on-course'}
      >
        <span>On-Course</span>
        {showServicesSubMenuItem === 'on-course' ? <Minus /> : <Plus />}
      </Header>
      <SubMenuItem visible={showServicesSubMenuItem === 'on-course'}>
        <StyledDropdownLink to="/holeinone">
          Hole-in-One Packages
        </StyledDropdownLink>
        <StyledDropdownLink to="/contests">
          Putting & Shootout Contests
        </StyledDropdownLink>
        <StyledDropdownLink to="/signs">Signs & Banners</StyledDropdownLink>
        <StyledDropdownLink to="/launcher">
          Golf Ball Launcher
        </StyledDropdownLink>
        <AdditionalHeader as="div">Additional Services</AdditionalHeader>
        <AdditionalServicesLink to="/cigar-rolling">
          Cigar Rolling
        </AdditionalServicesLink>
        <AdditionalServicesLink to="/club-rentals">
          Club Rentals
        </AdditionalServicesLink>
        <AdditionalServicesLink to="/photography">
          Photography
        </AdditionalServicesLink>
      </SubMenuItem>
    </DropdownList>
  )
}
export default ServicesMobileMenu
