import React from 'react'
import {useEffect} from 'react'
import {useLocation} from 'react-router'

const ScrollToTop = props => {
  const location = useLocation()
  useEffect(() => {
    if (!props.dontScroll) {
      window.scrollTo(0, 0)
    }
  }, [location, props.dontScroll])

  return <>{props.children}</>
}

export default ScrollToTop
