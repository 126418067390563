import * as React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useScrollPosition} from '@n8tb1t/use-scroll-position'

import styled from 'styled-components/macro'
import Logo from 'assets/images/Logo1.svg'
import {QUERIES} from 'common/constants'
import ServicesDropdown from 'common/ServicesDropdown'

const Wrapper = styled.div`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
  position: fixed;
  top: ${p => (p.show === true ? 0 : '-200px')};
  transition: all 0.5s ease-in-out;
  width: 100%;
  color: white;
  justify-content: center;
`

const StyledLogo = styled.img`
  height: 63px;
`

const Banner = styled.header`
  padding: 24px;
  font-family: Mulish;
  font-size: calc(24 / 16 * 1rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darker-grey);
  color: var(--white);
  width: 100%;
`

const Links = styled.div`
  display: none;
  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
  color: white;
  gap: 16px;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: calc(16 / 16 * 1rem);
  font-weight: 300;
  font-family: 'Rubik';
  text-transform: uppercase;
  font-style: italic;
  text-decoration: none;
  width: 119px;
  height: 71px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  :hover {
    background-color: var(--dark-grey);
  }
`

const StyledServicesLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: calc(16 / 16 * 1rem);
  font-weight: 300;
  font-family: 'Rubik';
  text-transform: uppercase;
  font-style: italic;
  text-decoration: none;
  width: 119px;
  height: 71px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  :hover {
    background-color: var(--dark-grey);
  }
  background-color: ${p =>
    p.active === 'true' ? 'var(--dark-grey)' : 'intial'};
`

const ScrollingHeaderBar = () => {
  const navigate = useNavigate()
  const [showHeader, setShowHeader] = React.useState(false)
  const [showServices, setShowServices] = React.useState(false)
  const toggleServicesDropdown = () => {
    setShowServices(!showServices)
  }

  useScrollPosition(({currPos}) => {
    const isShow = currPos.y < -200
    setShowHeader(isShow)
  }, [])
  return (
    <Wrapper show={showHeader}>
      <Banner>
        <StyledLogo src={Logo} onClick={() => navigate('/')} />
        <Links>
          <StyledLink to="/">Home</StyledLink>
          <StyledServicesLink
            onClick={toggleServicesDropdown}
            active={String(showServices)}
          >
            Services <ServicesDropdown visible={showServices} />
          </StyledServicesLink>
          <StyledLink to="/shop">Shop</StyledLink>
          <StyledLink to="/about">About</StyledLink>
          <StyledLink to="/contact">Contact</StyledLink>
        </Links>
      </Banner>
    </Wrapper>
  )
}
export default ScrollingHeaderBar
