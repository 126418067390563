import * as React from 'react'
import styled from 'styled-components/macro'

const BasicButtton = styled.button`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  padding: 12px 24px;
  font-weight: 700;
  color: white;
  background-color: var(--light-green);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  text-decoration: none;
`

const Button = ({message, onClick, className, children}) => {
  return (
    <BasicButtton className={className} onClick={onClick}>
      {message || children}
    </BasicButtton>
  )
}
export default Button
