import * as React from 'react'
import {useLocation} from 'react-router-dom'

const useLocationChange = action => {
  const location = useLocation()
  React.useEffect(() => {
    action(location)
  }, [action, location])
}

export default useLocationChange
