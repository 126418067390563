import * as React from 'react'
import styled from 'styled-components/macro'
import {useTransition, config, animated} from '@react-spring/web'
import {DialogOverlay, DialogContent} from '@reach/dialog'
import '@reach/dialog/styles.css'

import {QUERIES} from './constants'

// export const StandardModalHeader = props => {
//   let {onDismiss, caption} = props
//   return (
//     <>
//       <div className="standard-reach-header">
//         <div className="modal-title">{caption}</div>
//         <span
//           style={{cursor: 'pointer', marginLeft: 'auto'}}
//           className="close"
//           onClick={onDismiss}
//         >
//           <span>&times;</span>
//         </span>
//       </div>
//     </>
//   )
// }

const Wrapper = styled.div`
  position: relative;
`

const CloseIcon = styled.div`
  position: fixed;
  top: 12px;
  right: 12px;
  @media ${QUERIES.tabletAndUp} {
    top: 28px;
    right: 28px;
  }
  cursor: pointer;
  padding: 16px;
  color: var(--dark-grey);
`

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

const Modal = props => {
  let {isOpen, onDismiss, focusRef = null, children, width} = props
  const modalTransition = useTransition(!!isOpen, {
    config: isOpen ? {...config.stiff} : {duration: 350},
    from: {opacity: 0, transform: `translate3d(0px, 50px, 0px)`},
    enter: {opacity: 1, transform: `translate3d(0px, 0px, 0px)`},
    leave: {opacity: 0, transform: `translate3d(0px, 50px, 0px)`},
  })

  return modalTransition(
    (styles, isOpen) =>
      isOpen && (
        <AnimatedDialogOverlay
          allowPinchZoom={true}
          initialFocusRef={focusRef}
          onDismiss={onDismiss}
          isOpen={isOpen}
          style={{opacity: styles.opacity}}
        >
          <AnimatedDialogContent
            style={{
              boxShadow: '10px 10px 10px rgba(26, 26, 26, 0.65)',
              padding: 0,
              width: width ? width : '1100px',
              maxWidth: "clamp('375px', '95vw', width ? width : '1100px')",
              ...styles,
            }}
            aria-label="Request a quote"
          >
            <Wrapper>
              <CloseIcon onClick={onDismiss}>X</CloseIcon>
              {children}
            </Wrapper>
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      ),
  )
}

export default Modal
